"use client";

import * as React from "react";
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { usePathname, useRouter } from "next/navigation";
import { Group, Practice, Profile } from "@/lib/db/types";
import { admin_routes, practice_routes, routes } from "@/lib/routes";
import { ArrowLeftCircleIcon, Ellipsis, Home, Trash2 } from "lucide-react";
import { toast } from "@/components/ui/use-toast";
import { NewPracticeDialog } from "./new-practice-dialog";
import Link from "next/link";
import { archivePractice } from "@/server/practices";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../ui/dropdown-menu";
import { Spinner } from "@knocklabs/react-notification-feed";

interface PracticeSwitcherProps {
  profile: Profile;
  practices: Practice[];
  group?: Group;
}

export default function PracticeSwitcherDropdown({
  profile,
  practices,
  group,
}: PracticeSwitcherProps) {
  const [open, setOpen] = React.useState(false);
  const [showNewPracticeDialog, setShowNewPracticeDialog] =
    React.useState(false);
  const pathname = usePathname();

  React.useEffect(() => {
    const practiceSlug = pathname.split("/")[1];
    setSelectedPractice(
      practices.find((p) => p.slug === practiceSlug) || practices[0]
    );
  }, [pathname, practices]);

  const [selectedPractice, setSelectedPractice] = React.useState<Practice>(
    practices.find((p) => pathname.includes(p.slug)) || practices[0]
  );
  const router = useRouter();

  const adminsRoutes = Object.values(admin_routes).map((route) => route.path);
  const isInAdminPage = adminsRoutes.includes(pathname);
  const isInCat = pathname.startsWith(routes.cat.path);
  const isInGroup = pathname.startsWith("/group");

  const handleSwitchPractice = async (practice: Practice) => {
    setOpen(false);
    try {
      setSelectedPractice(
        practices.find((p) => p.id === practice.id) || practices[0]
      );
      toast({
        title: "Success",
        description: "Now viewing practice " + practice.name + ".",
        variant: "default",
      });
      // @ts-ignore
      router.push(`/${practice.slug}${practice_routes.requests.path}`);
    } catch (error) {
      toast({
        title: "Something went wrong.",
        description: "Please try again later.",
        variant: "destructive",
      });
    }
  };

  const isAdmin = profile.role === "admin";
  const [isArchiving, setIsArchiving] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleArchivePractice = async (practice_id: string) => {
    setIsArchiving(true);
    await archivePractice(practice_id).then(() => {
      setIsArchiving(false);
      setOpenDialog(false);
    });
  };

  const handleClick = () => {
    setOpenDialog(true);
  };

  if (!isInAdminPage) {
    return (
      <Dialog
        open={showNewPracticeDialog}
        onOpenChange={setShowNewPracticeDialog}
      >
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="ghost"
              role="combobox"
              aria-expanded={open}
              aria-label="Select a practice"
              className={cn(
                "w-[320px] justify-between bg-card/5 text-secondary-foreground hover:bg-card/10 hover:text-card dark:bg-card-foreground/5 dark:hover:bg-card-foreground/10 dark:hover:text-card-foreground"
              )}
            >
              <Avatar className="mr-2 h-5 w-5 items-center bg-muted/20 text-xs">
                {isInCat || isInGroup ? (
                  <Home className="mb-0.5 ml-0.5 h-4 w-4 text-secondary-foreground" />
                ) : (
                  <AvatarFallback className="bg-transparent">
                    {selectedPractice.name.substring(0, 2)}
                  </AvatarFallback>
                )}
              </Avatar>
              {isInCat
                ? routes.cat.name
                : isInGroup
                  ? "Home"
                  : selectedPractice.name}
              <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[320px] p-0">
            <Command>
              <CommandList>
                <CommandInput placeholder="Search practice..." />
                <CommandEmpty>No practice found.</CommandEmpty>
                {(profile.role == "admin" || profile.role == "cat") && (
                  <CommandGroup heading="Internal">
                    <CommandItem
                      onSelect={() => {
                        setOpen(false);
                        router.push("/cat");
                      }}
                      className="text-sm"
                    >
                      <Home className="mr-2 h-4 w-4" />
                      {routes.cat.name}
                      {isInCat && (
                        <CheckIcon className={cn("ml-auto h-4 w-4")} />
                      )}
                    </CommandItem>
                  </CommandGroup>
                )}

                {group && (
                  <CommandGroup heading="Internal">
                    <CommandItem
                      onSelect={() => {
                        setOpen(false);
                        router.push(`/group/${group.slug}/requests`);
                      }}
                      className="text-sm"
                    >
                      <Home className="mr-2 h-4 w-4" />
                      Home
                      {isInGroup && (
                        <CheckIcon className={cn("ml-auto h-4 w-4")} />
                      )}
                    </CommandItem>
                  </CommandGroup>
                )}

                <CommandGroup heading="Practices">
                  {practices.map((practice) => (
                    <Dialog
                      key={practice.id}
                      open={openDialog}
                      onOpenChange={setOpenDialog}
                    >
                      <CommandItem
                        onSelect={() => {
                          handleSwitchPractice(practice);
                        }}
                        className="text-sm w-full"
                      >
                        {!isInCat &&
                        !isInGroup &&
                        selectedPractice.slug === practice.slug ? (
                          <CheckIcon className="ml-0.5 h-4 w-4 mr-2.5" />
                        ) : (
                          <Avatar className="mr-2 h-5 w-5 text-xs">
                            <AvatarFallback>
                              {practice.name.substring(0, 2)}
                            </AvatarFallback>
                          </Avatar>
                        )}
                        {practice.name}
                        {isAdmin && (
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                className="ml-auto text-muted-foreground"
                                variant="ghost"
                                size="icon"
                                onClick={handleClick}
                              >
                                <Ellipsis className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="start">
                              <DialogTrigger asChild>
                                <DropdownMenuItem
                                  //Could afford to change the default for text-destructive in dark mode
                                  className="items-center"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Trash2 className="w-4 h-4 mr-1" />
                                  Archive Practice
                                </DropdownMenuItem>
                              </DialogTrigger>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        )}
                      </CommandItem>
                      <DialogContent>
                        <DialogHeader>Warning!</DialogHeader>
                        <DialogDescription>
                          This will remove any users and groups from the
                          practice, then remove the practice from CATWISE.
                        </DialogDescription>
                        <Button
                          className="w-40"
                          disabled={isArchiving}
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await handleArchivePractice(practice.id);
                          }}
                        >
                          {isArchiving ? (
                            <div className="w-4 h-4 mr-1">
                              <Spinner color={"currentColor"} />
                            </div>
                          ) : (
                            <Trash2 className="w-4 h-4 mr-1" />
                          )}
                          Archive Practice
                        </Button>
                      </DialogContent>
                    </Dialog>
                  ))}
                </CommandGroup>
              </CommandList>
              {(profile.role === "admin" || profile.role === "cat") && (
                <>
                  <CommandSeparator />
                  <CommandList>
                    <CommandGroup>
                      <DialogTrigger asChild>
                        <CommandItem
                          onSelect={() => {
                            setOpen(false);
                            setShowNewPracticeDialog(true);
                          }}
                        >
                          <PlusCircledIcon className="mr-2 h-5 w-5" />
                          Create Practice
                        </CommandItem>
                      </DialogTrigger>
                    </CommandGroup>
                  </CommandList>
                </>
              )}
            </Command>
          </PopoverContent>
        </Popover>
        {(profile.role === "admin" || profile.role === "cat") && (
          <NewPracticeDialog
            setShowNewPracticeDialog={setShowNewPracticeDialog}
          />
        )}
      </Dialog>
    );
  } else {
    return (
      <Link
        className={cn(buttonVariants({ variant: "outline" }), "w-[290px]")}
        href={"/cat"}
      >
        <ArrowLeftCircleIcon className="mr-3 h-4 w-4" />
        <p>Back to Central Advice Team</p>
      </Link>
    );
  }
}
